<template>
  <div class="OrderMax">
    <!--背景星空特效 -->
    <canvas id="myCanvas"></canvas>
    <!--面包屑 -->

    <!-- 中间 -->
    <div class="OrderMiddle">
      <div class="BazCrumbs">
        <span style="cursor:pointer;" @click="DjHomePage()">随心购物首页</span>
        <span style="margin-left:1.2vh;">></span>
        <span style="margin-left:1.2vh;cursor:pointer;" @click="DjDetails(detailed.id)">随心购物详情</span>
        <span style="margin-left:1.2vh;">></span>
        <span style="margin-left:1.2vh;cursor:pointer;">商品购买页面</span>
      </div>
      <!-- 头部 -->
      <div class="OrderTop">
        <!-- 收货地址 -->
        <div class="OrderShipping" style="z-index:999;">收货地址 <span
            style="font-size:2vh;color:rgba(100, 162, 255, 1); margin-left:1vh;cursor: pointer;z-index:999;"
            @click="newly()">新增收货地址</span> </div>
      </div>
      <!--点击添加收货地址 -->
      <div class="OrderAdd" v-if="add" @click="OrderAdd()">
        <!-- 加号图片 -->
        <div class="OrderPicture"> <img src="../../../assets/CulturalCreation/Bazaar/newly.png" alt=""
            style="width:100%;height:100%;"> </div>
        <!--文字 -->
        <div class="OrderCharacters">快去手动添加收货地址叭~</div>
      </div>
      <!-- 收货地址输入框 -->
      <div class="OrderShippingOne" v-if="site">
        <!-- 左边 -->
        <div class="OrderLeft">
          <!-- 标题 -->
          <div class="OrderTitle">
            <!-- 圆点 -->
            <div class="OrderCircle"> </div>
            <!-- 地址 -->
            <div class="OrderSite">收货地址：</div>
          </div>
          <!-- 框 -->
          <div class="OrderFlex">
            <!-- 市 -->
            <div class="OrderCity"> <span style="margin-left:2vh;">{{ address.provinceName }}</span> </div>
            <!-- 市 -->
            <div class="OrderCity"> <span style="margin-left:2vh;">{{ address.cityName }}</span> </div>
            <!-- 市 -->
            <div class="OrderCity"> <span style="margin-left:2vh;">{{ address.districtName }}</span> </div>
          </div>
        </div>
        <!-- 右边 -->
        <div class="OrderRight">
          <!-- 详细地址 -->
          <div class="OrderDetail">
            <!-- 圆点 -->
            <div class="OrderCircle"> </div>
            <!-- 地址 -->
            <div class="OrderSite">详细地址：</div>
          </div>
          <!-- 框 -->
          <div class="OrderCityOne"><span style="margin-left:2vh;">{{ address.address }}</span></div>
        </div>
      </div>
      <!-- 收件人 -->
      <div class="OrderRecipients" v-if="site">
        <!--左边 -->
        <div class="OrderLeftOne">
          <!-- 标题 -->
          <div class="OrderTitle">
            <!-- 圆点 -->
            <div class="OrderCircle"> </div>
            <!-- 地址 -->
            <div class="OrderSite">收货人：</div>
          </div>
          <!-- 收货人 -->
          <div class="OrderName"><span style="margin-left:2vh;">{{ address.deliName }}</span></div>
        </div>
        <!-- 右边 -->
        <div class="OrderRightOne">
          <!-- 标题 -->
          <div class="OrderTitle">
            <!-- 圆点 -->
            <div class="OrderCircle"> </div>
            <!-- 地址 -->
            <div class="OrderSite">收货人：</div>
          </div>
          <!-- 手机类型 -->
          <div class="OrderType"> <span style="margin-left:2vh;">中国大陆 +86</span></div>
          <!-- 手机号码-->

          <div class="OrderPhone"> <span style="margin-left:2vh;">{{ address.tel }}</span></div>
        </div>
      </div>
      <!-- 订单框 -->
      <div class="OrderBox">
        <!-- 确认订单 -->
        <div class="OrderAffirm"> <span style="margin-left:3vh;">确认订单</span> </div>
        <!-- 信息框 -->
        <div class="OrderBoxOne">
          <!-- 商品信息..商品 -->
          <div class="OrderFlexOne">
            <!-- 商品 -->
            <div class="OrderComm">商品</div>
            <div class="OrderMessage">商品信息</div>
          </div>
          <!-- 单价..数量什么的 -->
          <div class="OrderFlexTwo">
            <!-- 左边 -->
            <div class="OrderLeftTwo">
              <!-- 单价 -->
              <div class="OrderUnit">单价</div>
              <!-- 数量 -->
              <div class="OrderQuantity">数量</div>
            </div>
            <!-- 右边 -->
            <div class="OrderRightTwo">
              <!-- 优惠方式 -->
              <div class="OrderUnit">优惠方式</div>
              <!-- 小计（元） -->
              <div class="OrderQuantity">小计(元)</div>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="OrderBoxTwo">
          <div style="width:100%; height:40%;display: flex;justify-content: space-between; margin-top:4vh;">
            <!-- 商品内容 -->
            <div class="OrderThree">
              <!-- 商品图片 -->
              <div class="OrderPictureOne"> <img :src="detailed.img" alt="" style="width:100%;height: 100%;"> </div>
              <!-- 文字 -->
              <div class="OrderCharactersOne">{{ detailed.shuliang }}</div>
            </div>
            <!-- 价格 数量 -->
            <div class="OrderPrice">
              <!-- 单价 -->

              <div class="OrderUnitPrice">￥{{ detailed.jiage / 100 }}</div>
              <div class="OrderAmount">{{ detailed.soldNum }}</div>
            </div>
            <!-- 优惠方式、、小计 -->
            <div class="OrderBoxther">
              <!-- 优惠方式 -->
              <div class="OrderUnitPrice">无</div>
              <!-- 小计 -->
              <div class="OrderAmount">￥{{ detailed.jiage / 100 * detailed.soldNum }}</div>
            </div>
          </div>
          <!-- 留言 -->
          <div class="OrderBoxFour">
            <!-- 给买家留言 -->
            <div class="OrderLeave">给卖家留言：</div>
            <!-- 留言框 -->
            <div class="OrderLeaveBox"> <textarea name="description" class="OrdText" placeholder="请输入"
                v-model="ShuRu"></textarea> </div>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="OrderButton">
          <!-- 左边 -->
          <div style="width:30%;height:100%;"></div>
          <!-- 右边 -->
          <div style="width:35%;height:100%;display: flex;justify-content: space-between;">
            <div class="OrderButtonOne" @click="cancel()"> <span
                style="color:rgba(255, 255, 255, 1);font-size:2.5vh;">取消订单</span> </div>
            <div class="OrderButtonTwo" @click="TiJiao()"> <span
                style="color:rgba(255, 255, 255, 1);font-size:2.5vh;">确认订单</span> </div>
            <el-dialog v-model="centerDialogVisible" title="提示" width="30%" align-center class="my-info-dialog">
              <div style="width:20%;height:10vh;margin: 0 auto;border-radius:50%;"> <img
                  src="../../../assets/CulturalCreation/Bazaar/Dj.png" alt="" style="width:100%;height:100%;"> </div>
              <div style="width:15%; margin:0 auto;color: rgba(255, 255, 255, 1);font-size: 1.9vh;margin-top:2vh;">提交成功
              </div>

              <div style="text-align: center;margin-top: 20px;color: rgba(255, 255, 255, 1)">管理员会根据您填写的信息联系到您</div>
              <div style="text-align: center;margin-top: 20px;color: rgba(255, 255, 255, 1)">管理员联系方式:1234664446</div>
              <!-- centerDialogVisible -->
              <template #footer>
                <span class="dialog-footer">
                  <el-button type="primary" @click="confirm()" style="width:100px;margin-bottom:30px;">
                    确定
                  </el-button>
                </span>
              </template>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 评价输入
      ShuRu: '',
      DetailedOne: {},
      detailed: {},
      //  详情
      particulars: {},
      //收货地址
      shipping: [],
      address: {},
      // 提交信息栏
      centerDialogVisible: false,
      //点击添加地址
      add: true,
      // 地址的隐藏显示
      site: false,

      // 收货地址id
      addrId: '',
      // 订单id
      orderId: '',
      deviceId: 1,
      // 商品列表
      item: [
        {
          goodsId: '',
          productNum: '',
        }
      ],
      form:
      {
        imgs: '',
        title: '',
        orderId: '',
        GoodsId: '',
      },
      // 商品详情数据
      details: {
        img: '',
        title: '',
        jiage: '',
        soldNum: '',
        shuliang: '',
        deviceId: 0,
        id: '',
      },
      id: '',
      ZhuanTia: '',
    }

  },
  created() {
    this.OrderForm()
    //  this.OrderFormOne()
    // 收货地址接口
    this.GetSiteList()
  },
  mounted() {
    this.XingKong()
    //接收地址返回的数据
    this.JieShou()
  },
  methods: {
    //点击返回购物首页
    DjHomePage() {
      this.$router.push('/ShopAtWillHome');
    },
    //点击返回购物详情  
    DjDetails(id) {
      this.$router.push({
        path: '/Bazaar',
        query: {
          id: id
        }
      });
    },
    //星空特效
    XingKong() {
      var myCanvas = document.getElementById('myCanvas');
      var ctx = myCanvas.getContext("2d");
      var starlist = [];
      function init() {
        // 设置canvas区域的范围为整个页面
        myCanvas.width = window.innerWidth;
        myCanvas.height = window.innerHeight;
      };
      init();
      // 监听屏幕大小改变 重新为canvas大小赋值
      // window.onresize = init;

      var list = [];
      // 将页面上每一个小圆点的信息存在list数组中
      // 数组中的每一位是一个对象  对象中存着每一个小点的信息
      // 利用构造函数生成数组

      function Star(x, y, radius, disX, disY) {
        // 将传入的参数设置为对象的属性
        // 位置坐标
        this.x = x;
        this.y = y;
        // 半径
        this.radius = radius;
        // 变化距离
        this.disX = disX;
        this.disY = disY;
      }

      for (var i = 0; i < 800; i++) {
        // 设置位随机值
        var x = Math.random() * myCanvas.width;
        var y = Math.random() * myCanvas.height;
        var radius = Math.random() * 2;
        var disX = x - myCanvas.width / 2;
        var disY = y - myCanvas.height / 2;
        // 每一个的信息存在对象中  所有小点存在list数组中
        list.push(new Star(x, y, radius, disX, disY));
      }

      // 绘制并且运动函数
      function animate() {
        // 在每次渲染前清除一下屏幕
        ctx.clearRect(0, 0, myCanvas.width, myCanvas.height);
        // 下一次渲染  根据存放在数组中的信息进行画小星星
        for (var i = 0; i < 800; i++) {
          // 取出每一个信息点信息
          var a = list[i];

          // a的坐标在改变  根据disx disy进行改变  /50改变值设置的小一点
          a.x += a.disX / 50;
          a.y += a.disY / 50;

          // 如果小圆点运动到边界之外
          if (a.x < 0 || a.y < 0 || a.x > myCanvas.width || a.y > myCanvas.height) {
            // 重新出现一个小星星的位置
            a.x = Math.random() * myCanvas.width;
            a.y = Math.random() * myCanvas.height;
            // 同时根据 当前新出现的位置设置移动距离
            a.disX = a.x - myCanvas.width / 2;
            a.disY = a.y - myCanvas.height / 2;
          }

          // 开始画每一个点
          ctx.beginPath();
          // 设置填充颜色
          ctx.fillStyle = "#00F5FF";
          // 每一小星星为一个小圆
          ctx.arc(a.x, a.y, a.radius, 0, Math.PI * 2, false);
          // 进行填充
          ctx.fill();
        }
        // 不断绘制canvas  根据disx与disy的位置变化  更新坐标  视觉上位运动状态
        setTimeout(animate, 60);
      }
      animate();
    },
    // 商品订单信息
    OrderForm() {
      this.detailed = this.$route.query;
    },
    // 点击返回商品首页
    cancel() {
      this.$router.push('/ShopAtWillHome');
    },

    newly() {
      var axios = require('axios');
      var config = {
        method: 'get',
        url: this.$Schttp + '/vtp/app/customerDeliAddr/list',
        // params:{
        //     GoodsCodeid:this.GoodsCodeid
        // }
        headers: {
          Authorization: 'Bearer ' + this.$cookies.get("token"),
          'Content-Type': 'application/json'
        },
      };
      axios(config)
        .then((res) => {
          if (res.data.code == 200) {
            this.details.img = this.detailed.img
            this.details.title = this.detailed.title
            this.details.jiage = this.detailed.jiage
            this.details.soldNum = this.detailed.soldNum
            this.details.shuliang = this.detailed.shuliang
            this.details.deviceId = this.deviceId
            this.details.id = this.detailed.id
            this.$router.push({
              path: '/personalCenter/colRest',
              query: this.details
            });
          }
          if (res.data.code == 401) {
            this.$message({
              message: "请先登录",
              type: 'success'
            })
            this.$router.push("/login");

          } else if (this.shipping == '') {
            this.details.img = this.detailed.img
            this.details.title = this.detailed.title
            this.details.jiage = this.detailed.jiage
            this.details.soldNum = this.detailed.soldNum
            this.details.shuliang = this.detailed.shuliang
            this.details.deviceId = this.deviceId
            this.details.id = this.detailed.id
            this.$router.push({
              path: '/personalCenter/colRest',
              query: this.details
            });
          }

        })
        .catch(function (error) {
        });

    },
    // 收货地址接口请求
    GetSiteList() {
      var axios = require('axios');
      var config = {
        method: 'get',
        url: this.$Schttp + '/vtp/app/customerDeliAddr/list',
        // params:{
        //     GoodsCodeid:this.GoodsCodeid
        // }
        headers: {
          Authorization: 'Bearer ' + this.$cookies.get("token"),
          'Content-Type': 'application/json'
        },
      };
      axios(config)
        .then((res) => {
          if (res.data.code == 200) {
            this.shipping = res.data.data
            for (let i = 0; i < this.shipping.length; i++) {
              if (this.shipping[i].defaultTag == 1) {
                this.address = this.shipping[i]
              }
            }
            this.addrId = this.address.addrId
            if (this.shipping != '') {
              this.add = false,
                this.site = true
            }
          }


        })
        .catch(function (error) {
        });
    },
    // 点击跳转登录
    OrderAdd() {
      var axios = require('axios');
      var config = {
        method: 'get',
        url: this.$Schttp + '/vtp/app/customerDeliAddr/list',
        // params:{
        //     GoodsCodeid:this.GoodsCodeid
        // }
        headers: {
          Authorization: 'Bearer ' + this.$cookies.get("token"),
          'Content-Type': 'application/json'
        },
      };
      axios(config)
        .then((res) => {

          if (res.data.code == 401) {
            this.$message({
              message: "请先登录",
              type: 'success'
            })
            this.$router.push("/login");
          } else if (this.shipping == '') {
            this.details.img = this.detailed.img
            this.details.title = this.detailed.title
            this.details.jiage = this.detailed.jiage
            this.details.soldNum = this.detailed.soldNum
            this.details.shuliang = this.detailed.shuliang
            this.details.deviceId = this.deviceId
            this.details.id = this.detailed.id
            this.$router.push({
              path: '/personalCenter/colRest',
              query: this.details
            });
          }
        })
        .catch(function (error) {
        });


    },
    // 提交订单
    TiJiao() {
      this.item[0].goodsId = this.detailed.id
      this.item[0].productNum = this.detailed.soldNum
      var axios = require('axios');
      var config = {
        method: 'POST',
        url: this.$Schttp + '/vtp/app/order/createOrder',
        // params:{
        //     GoodsCodeid:this.GoodsCodeid
        // }
        headers: {
          Authorization: 'Bearer ' + this.$cookies.get("token"),
          'Content-Type': 'application/json'
        },

        data: {
          addrId: this.addrId,
          userRemark: this.ShuRu,
          items: this.item,
        }

      };
      axios(config)
        .then((res) => {
          if (res.data.code == 200) {
            this.orderId = res.data.data
            this.centerDialogVisible = true
          }
          if (res.data.code == 401) {
            this.$message({
              message: "请先登录",
              type: 'success'
            })
            this.$router.push('/login')
          }
          if (res.data.code == 500) {
            this.$message({
              message: "请填写收货地址",
              type: 'success'
            })
            this.$router.push('/login')
          }


        })
        .catch(function (error) {
        });
    },
    confirm() {
      this.form.imgs = this.detailed.img
      this.form.title = this.detailed.shuliang
      this.form.orderId = this.orderId
      this.form.GoodsId = this.detailed.id
      this.centerDialogVisible = false
      this.$router.push({
        path: '/evaluate',
        query: this.form,
      }


      )
    },
    JieShou() {
      this.detailed = this.$route.query;
    }
  },
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.OrderMax {
  width: 100%;
  height: 100%;
  position: absolute;
  background: radial-gradient(200% 100% at bottom center, #013990, #6DADFB, #1b2947);
  background: radial-gradient(200% 105% at top center, #013990 10%, #6DADFB 40%, #013990 65%, #1b2947);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  #myCanvas {
    width: 100%;
    position: absolute;
    z-index: 1;
  }

  .OrderMiddle {
    width: 80%;
    height: 100%;
    z-index: 999;

    .BazCrumbs {
      color: #FFFFFF;
      font-size: 1.7vh;
      z-index: 999;
      margin-top: 3vh;
    }

    .OrderTop {
      width: 20%;
      color: rgba(255, 255, 255, 1);
      font-size: 3vh;
      z-index: 999;
      margin-top: 3vh;
    }

    .OrderAdd {
      width: 50%;
      height: 7%;
      border: 1px solid rgba(255, 255, 255, 1);
      border-radius: 0.5vh;
      margin-top: 2vh;
      display: flex;
      align-items: center;
      cursor: pointer;

      .OrderPicture {
        width: 4%;
        height: 60%;
        margin-left: 2vh;
      }

      .OrderCharacters {
        color: #DDDDDD;
        font-size: 1.8vh;
        margin-left: 2vh;
        cursor: pointer;
      }
    }

    .OrderShippingOne {
      width: 100%;
      height: 7%;
      margin-top: 2vh;
      display: flex;
      justify-content: space-between;


      .OrderLeft {
        width: 47%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        .OrderTitle {
          width: 17%;
          height: 100%;
          display: flex;
          align-items: center;

          .OrderCircle {
            width: 8%;
            height: 20%;
            background: #64A2FF;
            border-radius: 50%;
          }

          .OrderSite {
            color: #E3E3E3;
            font-size: 2vh;
            margin-left: 1.2vh;
          }
        }

        .OrderFlex {
          width: 83%;
          height: 100%;
          display: flex;
          justify-content: space-between;

          .OrderCity {
            width: 32%;
            height: 100%;
            border: 1px solid rgba(210, 210, 210, 1);
            color: rgba(221, 221, 221, 1);
            border-radius: 0.5vh;
            font-size: 2vh;
            display: flex;
            align-items: center;
          }
        }
      }

      .OrderRight {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        .OrderDetail {
          width: 16%;
          height: 100%;
          display: flex;
          align-items: center;

          .OrderCircle {
            width: 8%;
            height: 20%;
            background: #64A2FF;
            border-radius: 50%;
          }

          .OrderSite {
            color: #E3E3E3;
            font-size: 2vh;
            margin-left: 1.2vh;
          }
        }

        .OrderCityOne {
          width: 84%;
          height: 100%;
          border: 1px solid rgba(210, 210, 210, 1);
          border-radius: 0.5vh;
          color: rgba(221, 221, 221, 1);
          font-size: 2vh;
          display: flex;
          align-items: center;
        }
      }
    }

    .OrderRecipients {
      width: 100%;
      height: 7%;
      margin-top: 4vh;
      display: flex;
      justify-content: space-between;

      .OrderLeftOne {
        width: 22%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        .OrderTitle {
          width: 36%;
          height: 100%;
          display: flex;
          align-items: center;

          .OrderCircle {
            width: 8%;
            height: 20%;
            background: #64A2FF;
            border-radius: 50%;
          }

          .OrderSite {
            color: #E3E3E3;
            font-size: 2vh;
            margin-left: 1.2vh;
          }
        }

        .OrderName {
          width: 64%;
          height: 100%;
          border: 1px solid rgba(210, 210, 210, 1);
          border-radius: 0.5vh;
          color: rgba(221, 221, 221, 1);
          font-size: 2vh;
          display: flex;
          align-items: center;
        }
      }

      .OrderRightOne {
        width: 75%;
        height: 100%;
        display: flex;

        .OrderTitle {
          width: 13%;
          height: 100%;
          display: flex;
          align-items: center;

          .OrderCircle {
            width: 8%;
            height: 20%;
            background: #64A2FF;
            border-radius: 50%;
          }

          .OrderSite {
            color: #E3E3E3;
            font-size: 2vh;
            margin-left: 1.2vh;
          }
        }

        .OrderType {
          width: 24%;
          height: 100%;
          border: 1px solid rgba(210, 210, 210, 1);
          border-radius: 0.5vh;
          color: rgba(221, 221, 221, 1);
          font-size: 2vh;
          display: flex;
          align-items: center;
        }

        .OrderPhone {
          width: 60%;
          height: 100%;
          border: 1px solid rgba(210, 210, 210, 1);
          border-radius: 0.5vh;
          color: rgba(221, 221, 221, 1);
          font-size: 2vh;
          display: flex;
          align-items: center;
          margin-left: 3vh;
        }
      }
    }

    .OrderBox {
      width: 100%;
      margin-top: 8vh;

      .OrderAffirm {
        width: 100%;
        height: 8vh;
        border: 1px solid rgba(204, 204, 204, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 3vh;
        display: flex;
        align-items: center;
      }

      .OrderBoxOne {
        width: 100%;
        height: 6vh;
        border: 1px solid rgba(204, 204, 204, 1);
        color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: space-between;

        .OrderFlexOne {
          width: 25%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .OrderComm {
            font-size: 2vh;
            margin-left: 3vh;
          }

          .OrderMessage {
            font-size: 2vh;
          }
        }

        .OrderFlexTwo {
          width: 55%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .OrderLeftTwo {
            width: 35%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .OrderUnit {
              font-size: 2vh;
            }

            .OrderQuantity {
              font-size: 2vh;
            }
          }

          .OrderRightTwo {
            width: 35%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 6vh;

            .OrderUnit {
              font-size: 2vh;
            }

            .OrderQuantity {
              font-size: 2vh;
            }
          }
        }
      }

      .OrderBoxTwo {
        width: 100%;
        background: linear-gradient(180deg, #FFF8EF 0%, #F8CF8B 100%);
        border: 1px solid rgba(255, 255, 255, 0);

        .OrderThree {
          width: 35%;
          height: 12vh;
          display: flex;
          justify-content: space-between;
          flex-shrink: 0;

          .OrderPictureOne {
            width: 25%;
            height: 100%;
            margin-left: 2.7vh;
          }

          .OrderCharactersOne {
            width:65%;
            height:92%;
            color: rgba(90, 90, 90, 1);
            font-size: 1.7vh;
            display: flex;
            justify-content: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
          }
        }

        .OrderPrice {
          width: 22.5%;
          height: 100%;
          flex-shrink: 0;
          display: flex;
          justify-content: space-between;

          // border: 1px solid red;
          .OrderUnitPrice {
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(90, 90, 90, 1);
            font-size: 1.7vh;
          }

          .OrderAmount {
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(90, 90, 90, 1);
            font-size: 1.7vh;

          }
        }

        .OrderBoxther {
          flex-shrink: 0;
          display: flex;
          justify-content: space-between;
          width: 23%;
          height: 100%;
          margin-right: 4vh;
          flex-shrink: 0;

          .OrderUnitPrice {
            width: 45%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(90, 90, 90, 1);
            font-size: 1.7vh;
          }

          .OrderAmount {
            width: 28%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(241, 26, 26, 1);
            font-size: 1.7vh;

          }
        }

        .OrderBoxFour {
          width: 95%;
          margin-left: 2.9vh;
          display: flex;
          align-items: center;
          margin-top: 2vh;
          margin-bottom: 3vh;

          .OrderLeave {
            color: #5A5A5A;
            font-size: 1.7vh;
          }

          .OrderLeaveBox {
            width: 92%;
            height: 10vh;
            margin-left: 0.3vh;

            .OrdText {
              width: 100%;
              height: 100%;
              outline: none;
              color: #000;
              font-size: 1.8vh;
              resize: none;
              border: 1px solid rgba(204, 204, 204, 1);
            }
          }
        }
      }

      .OrderButton {
        width: 100%;
        height: 6.5vh;
        margin-top: 4vh;
        display: flex;
        justify-content: space-between;

        .OrderButtonOne {
          width: 45%;
          height: 100%;
          background: #B6CDEF;
          border-radius: 0.5vh;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .OrderButtonTwo {
          width: 45%;
          height: 100%;
          background: rgba(100, 162, 255, 1);
          border-radius: 0.5vh;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

      }

    }
  }
}
</style>
<style>
.el-dialog {
  background-color: rgba(100, 162, 255, 1);
}

.el-dialog__title {
  color: rgba(255, 255, 255, 1);
}

.el-result__title {
  color: rgba(255, 255, 255, 1);
}
</style>